@use '../includes' as *;

.accessibility_icons_icons {
  display: inline-flex;
  align-items: center;
  gap: $g4;
}

// need the extra specificity
span.icon {
  display: inline-flex;
  align-items: center;
  height: 16px;
  padding: 1.5px $g4;
  border: 1px solid currentcolor;
  border-radius: 2px;
  color: currentcolor;
  font-size: $f12;
  font-weight: $medium;
  letter-spacing: 0.1em;
  translate: 0 1px;

  > svg {
    width: 9px;
    height: 9px;
    margin: 0;
    fill: currentcolor;
  }
}
